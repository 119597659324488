import Navbar from './components/Navbar.jsx';
import Hero from './components/Hero.jsx';
import About from './components/About.jsx';
import Contact from './components/Contact.jsx';
import Services from './components/Services.jsx';
import Features from './components/Features.jsx';
import Footer from './components/Footer.jsx';
import ScrollToTopButton from './components/sub_components/scrollToTop.jsx';
import { DarkModeProvider } from './components/utils/DarkMode.jsx';

import './App.scss';

function App() {
  return (
    <div className="app">
      <DarkModeProvider>
        <Navbar/>
        <Hero/>
        <About/>
        <Services/>
        <Features/>
        <Contact/>
        <Footer/>
        <ScrollToTopButton/>
      </DarkModeProvider>
    </div>
  );
}

export default App;
