
import axios from 'axios';
import { useState } from 'react';
import { Card, Form, TextInput, TextArea, BudgetDropDown, Button } from './sub_components/subContact';

import './styles/contact.scss';

const sendDataToAPI = async (dataToSend) => {
    try{
        const res = await axios.post(
            process.env.REACT_APP_CONTACT_API_URL, 
            JSON.stringify(dataToSend),
            {
                headers:{
                    'Content-Type': 'application/json'
                }
            }
        );
        if (res.status !== 200) {
            throw new Error('Network response was not ok: ' + res.status);
        }
        console.log('Success:', res.data);
    } catch (err) {
        console.error('Problem with fetch operation', err);
    }
}

const Contact = () => {
    const [formData, setFormData] = useState({
        name: { label: 'Name', value: '', focus: false },
        email: { label: 'Email', value: '', focus: false },
        company: { label: 'Company Name', value: '', focus: false },
        budget: { label: 'Budget', value: '', focus: false },
        message: { label: 'How Can We Help?', value: '', focus: false },
    });

    const [isLoading, setIsLoading] = useState(false);
    const [isDone, setIsDone] = useState(false);

    const handleFocus = (e) => {
        const name = e.target.name;
        setFormData({
            ...formData,
            [name]: { ...formData[name], focus: true }
        });
    };

    const handleBlur = (e) => {
        const name = e.target.name;
        setFormData({
            ...formData,
            [name]: { ...formData[name], focus: false }
        });
    };

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setFormData({
            ...formData,
            [name]: { ...formData[name], value }
        });
    };

    const dataToSend = {
        name: formData.name.value,
        email: formData.email.value,
        company: formData.company.value,
        budget: formData.budget.value,
        message: formData.message.value,
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        await sendDataToAPI(dataToSend);
        setIsLoading(false);
        setIsDone(true);

        setTimeout(() => {
            setIsDone(false);
        }, 2000);
    }

    const { name, email, message, company, budget } = formData;

  return (
    <div id='contact'>
        <Card>
            <div className='contact_heading'>
                <p className='contact_title'>Contact Us</p>
                <h2>Free ai consultation</h2>
            </div>
            <Form onSubmit={handleSubmit} >
                <TextInput
                    {...name}
                    name="name"
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    autocomplete="name"
                />
                <TextInput
                    {...email}
                    name="email"
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    autocomplete="email"
                />
                <TextInput
                    {...company}
                    name="company"
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    onChange={handleChange}
                />
                <BudgetDropDown 
                    value={budget.value}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    onChange={handleChange}
                />
                <TextArea
                    {...message}
                    name="message"
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    onChange={handleChange}
                />
                <Button isLoading={isLoading} isDone={isDone} >Send</Button>
            </Form>
        </Card>
    </div>
  );
}

export default Contact;