import { motion } from 'framer-motion';

import { chatbot, desc2, desc3 } from '../assets/';
import './styles/services.scss';
// import './styles.css';

const services = [
    {
        title: 'Chatbot Development',
        img: chatbot,
        desc: 'AI-enhanced agents boost productivity by handling tasks like customer support, improving efficiency, reducing errors, and offering data-driven insights for a more autonomous and successful business future.'
    },
    {
        title: 'Bespoke Business Automation',
        img: desc2,
        desc: 'Unlock seamless operations with custom automation tailored to your specific business processes, all designed with powerful no-code platforms.'
    },
    {
        title: 'AI & Automation Consultation',
        img: desc3,
        desc: 'We offer specialized guidance to integrate AI and automation effectively into your business, enhancing efficiency and decision-making for a competitive edge in the digital world.'
    }
];

const ServiceCard = ({title, img, desc}) => {
    return (
        <motion.div 
            className="service_card"
            whileHover={{
                scale: 1.05,
            }}
        >
            <img src={img} alt='List of services' />
            <h3 className='service_heading'>{title}</h3>
            <span className='breakLine'></span>
            <p className='service_desc'>{desc}</p>
        </motion.div>
    )
}

const Services = () => {
    return (
        <section id="services">
            <div className='services_heading'>
                <p className="service_title">Services</p>
                <h2>Business Automation for Future-Ready Businesses</h2>
            </div>
            <div className="cards">
                {services.map((services, index) => (
                    <ServiceCard 
                        key={index}
                        title={services.title}
                        img={services.img}
                        desc={services.desc}
                    />
                ))}
            </div>

        </section>
    )
}


// const Services = () => {
//     return (
//         <div class="cards-1">
//             <div class="container">
//                 <div class="row">
//                     <div class="col-lg-12">
//                         <div class="above-heading">DESCRIPTION</div>
//                         <h2 class="h2-heading">Marketing Automation Will Bring More Qualified Leads</h2>
//                     </div>
//                 </div>
//                 <div class="row">
//                     <div class="col-lg-12">

//                         {/* Card */}
//                         <div class="desc_card">
//                             <div class="card-image">
//                                 <img class="img-fluid" src={desc1} alt="alternative"/>
//                             </div>
//                             <div class="card-body">
//                                 <h4 class="card-title">Lists Builder</h4>
//                                 <p>It's very easy to start creating email lists for your marketing actions. Just create your Tivo account</p>
//                             </div>
//                         </div>

//                         {/* Card */}
//                         <div class="desc_card">
//                             <div class="card-image">
//                                 <img class="img-fluid" src={desc2} alt="alternative"/>
//                             </div>
//                             <div class="card-body">
//                                 <h4 class="card-title">Campaign Tracker</h4>
//                                 <p>Campaigns is a feature we've developed since the beginning because it's at the core of Tivo's functionalities</p>
//                             </div>
//                         </div>

//                         {/* Card */}
//                         <div class="desc_card">
//                             <div class="card-image">
//                                 <img class="img-fluid" src={desc3} alt="alternative"/>
//                             </div>
//                             <div class="card-body">
//                                 <h4 class="card-title">Analytics Tool</h4>
//                                 <p>Tivo collects customer data in order to help you analyse different situations and take required action</p>
//                             </div>
//                         </div>

//                     </div>
//                 </div>
//             </div>
//         </div>
//     )
// }


export default Services;