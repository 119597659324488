import { useRef } from 'react';
import { getCalApi } from "@calcom/embed-react";
import { useEffect } from "react";

import { logo } from '../assets/';
import './styles/navbar.scss';


const NavButton = () => {
    useEffect(() => {
      (async function () {
        try {
          const cal = await getCalApi({});
          cal("ui", {
            theme: "light",
            cssVarsPerTheme: {
                light: {
                  '--cal-brand': '#5F4DEE',
                },
            },
            hideEventTypeDetails: false,
            layout: "monthly_view",
          });
        } catch (error) {
          console.error("Error loading Cal.com API:", error);
        }
      })();
    }, []);
    return (
      <button
        id="cta"
        data-cal-namespace=""
        data-cal-link="saurav-kc-flow-fusion/automation-consultation"
        data-cal-config='{"layout":"week_view"}'
      >
        Book A Call
      </button>
    );
};

const Navbar = () => {
    const checkBoxRef = useRef(null);
    const calendly = 'https://calendly.com/flowfusion-ai-meeting/ai-automation-discovery-call';

    const handleLinkClick = () => {
        if(checkBoxRef.current){
            checkBoxRef.current.checked = false;
        }
    }
    return (
        <nav className="nav-bar">
            <div className="nav_container">
                <input type="checkbox" id="drop-down-cbox" ref={checkBoxRef}/>
                <label htmlFor="drop-down-cbox">
                    <span></span>
                    <span></span>
                    <span></span>
                </label>

                <img src={logo} alt="Flow Fusion AI Logo" className='site-logo' />

                <ul className="main-nav small-caps">
                    <li><a href="#hero" onClick={handleLinkClick}>Home</a></li>
                    <li><a href='#about' onClick={handleLinkClick}>About Us</a></li>
                    <li><a href="#services" onClick={handleLinkClick}>Services</a></li>
                    <li><a href="#contact" onClick={handleLinkClick}>Contact</a></li>
                    <li>
                        <NavButton />
                    </li>
                </ul>

                
            </div>
        </nav>
    )
}
  

export default Navbar;